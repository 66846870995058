import { Button } from "primereact/button";
import React from "react";

export type Props = {
  onClick: () => any,
}

const Homepage = ({ onClick }: Props) => {
  return (<>
    <div className="grid grid-nogutter surface-section py-8">
      <div className="col-12 lg:col-6 py-8 text-center">
        <img src="/static/img/diagram-usecase.png" alt="Business architecture" />
      </div>
      <div className="col-12 lg:col-6 py-8">
        <div className="px-3 lg:px-5 mt-5 lg:mt-0">
          <div className="text-4xl font-bold text-900 mb-3">Business Architecture</div>
          <div className="text-700 mb-5 line-height-3 mb-5">Start your journey with business modeling. Dopredu has all you need to create, define, or document your business architecture. Let everyone on your team know and review the features of your product.</div>
          <div className="flex align-items-center mb-4">
            <i className="pi pi-check text-green-600 mr-3"></i>
            <div>
              <div className="text-900 font-medium mb-2">Simple to use</div>
              <div className="text-700 text-sm">Simple, intuitive, and modern UI has all you need to create use-case diagrams</div>
            </div>
          </div>
          <div className="flex align-items-center mb-4">
            <i className="pi pi-check text-green-600 mr-3"></i>
            <div>
              <div className="text-900 font-medium mb-2">UML standard support</div>
              <div className="text-700 text-sm">Add use cases, actors, boundaries, all types of relationships, or stereotypes</div>
            </div>
          </div>
          <div className="flex align-items-center mb-4">
            <i className="pi pi-check text-green-600 mr-3"></i>
            <div>
              <div className="text-900 font-medium mb-2">Export diagrams</div>
              <div className="text-700 text-sm">When you are ready, export diagrams as images and share them with your clients, product owners, or a whole team</div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 lg:col-6 py-8">
        <div className="px-3 lg:px-5 mt-5 lg:mt-0">
          <div className="text-4xl font-bold text-900 mb-3">IT Architecture</div>
          <div className="text-700 mb-5 line-height-3 mb-5">
            Creating great IT architecture is crucial for your future success. Keeping your design as simple as possible and easily extendable can be very difficult. We help you model the best cloud or on-premise technical landscape for solving your problems.
          </div>

          <div className="flex align-items-center mb-4">
            <i className="pi pi-check text-green-600 mr-3"></i>
            <div>
              <div className="text-900 font-medium mb-2">Simple to use</div>
              <div className="text-700 text-sm">Simple, intuitive, and modern UI has all you need to create a technical landscape</div>
            </div>
          </div>
          <div className="flex align-items-center mb-4">
            <i className="pi pi-check text-green-600 mr-3"></i>
            <div>
              <div className="text-900 font-medium mb-2">UML standard support</div>
              <div className="text-700 text-sm">Add components, define interfaces, use stereotypes, and connect all parts with proper links. Everything you need without a clutter</div>
            </div>
          </div>
          <div className="flex align-items-center mb-4">
            <i className="pi pi-check text-green-600 mr-3"></i>
            <div>
              <div className="text-900 font-medium mb-2">Cloud support</div>
              <div className="text-700 text-sm">Use cloud components to visualize your technology stack in the cloud</div>
            </div>
          </div>
          <div className="flex align-items-center mb-4">
            <i className="pi pi-check text-green-600 mr-3"></i>
            <div>
              <div className="text-900 font-medium mb-2">Export diagrams</div>
              <div className="text-700 text-sm">When you are ready, export diagrams as images and share them with your architects, developer, devops, or a whole team</div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 lg:col-6 py-8 text-center">
        <img src="/static/img/diagram-component.png" alt="IT Architecture" />
      </div>

      <div className="col-12 lg:col-6 py-8 text-center">
        <img src="/static/img/diagram-class.png" alt="Class diagram" />
      </div>
      <div className="col-12 lg:col-6 py-8">
        <div className="px-3 lg:px-5 mt-5 lg:mt-0">
          <div className="text-4xl font-bold text-900 mb-3">Software &amp; data modeling</div>
          <div className="text-700 mb-5 line-height-3 mb-5">Start a low-level model of your products with class diagrams, or model your data model. Dopredu helps you design your application in all aspects. Let every developer on your team know and review the software model you've created.</div>
          <div className="flex align-items-center mb-4">
            <i className="pi pi-check text-green-600 mr-3"></i>
            <div>
              <div className="text-900 font-medium mb-2">Simple to use</div>
              <div className="text-700 text-sm">Simple, intuitive, and modern UI has all you need to create class or data diagrams</div>
            </div>
          </div>
          <div className="flex align-items-center mb-4">
            <i className="pi pi-check text-green-600 mr-3"></i>
            <div>
              <div className="text-900 font-medium mb-2">UML standard support</div>
              <div className="text-700 text-sm">Add classes, interfaces, all types of relationships, or stereotypes</div>
            </div>
          </div>
          <div className="flex align-items-center mb-4">
            <i className="pi pi-check text-green-600 mr-3"></i>
            <div>
              <div className="text-900 font-medium mb-2">Export diagrams</div>
              <div className="text-700 text-sm">When you are ready, export diagrams as images and share them with developers</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </>);
}

export default Homepage;
