import React from "react";
import { Button } from "primereact/button";

export type Props = {
  loggedIn: boolean,
  onSignUpClick: (plan: "free" | "professional" | "team") => any,
  onOpenBillingClick: () => any,
}

const Pricing = ({ onSignUpClick, onOpenBillingClick, loggedIn }: Props) => {
  return (
    <div className="surface-section px-4 py-8 md:px-6 lg:px-8 border-round">
      <div className="text-900 font-bold text-6xl mb-4 text-center">Choose the plan that’s right for you</div>
      <div className="text-700 text-xl mb-6 text-center line-height-3">Monthly subscription, cancel any time. Start for free.</div>
      <div className="overflow-x-auto">
        <table className="w-full white-space-nowrap" style={{ borderCollapse: 'collapse', borderSpacing: '0' }}>
          <thead>
            <tr>
              <td className="surface-100 p-3">
              </td>
              <td className="surface-100 p-3 text-center vertical-align-top">
                <div className="text-900 text-2xl font-medium mb-3">Free</div>
                <div className="text-600 mb-3"><span className="text-3xl text-900">&euro;0</span> per month</div>
                {!loggedIn && <Button className="p-button-rounded" label="Sign up" onClick={() => onSignUpClick('free')} />}
              </td>
              {/* <td className="surface-100 p-3 text-center">
                <div className="text-900 text-xl font-medium mb-3">Professional</div>
                <div className="text-600 mb-3"><span className="text-3xl text-900">&euro;20</span> per user</div>
                <Button className="p-button-rounded" label="Sign up" onClick={() => onSignUpClick('professional')} />
              </td> */}
              <td className="surface-100 p-3 text-center vertical-align-top">
                <div className="text-900 text-2xl font-medium mb-3">Pro</div>
                <div className="text-600 mb-3"><span className="text-3xl text-900">&euro;50</span> per month</div>
                {!loggedIn && <Button className="p-button-rounded" label="Sign up" onClick={() => onSignUpClick('team')} />}
                {loggedIn && <Button className="p-button-rounded" label="Open profile" onClick={onOpenBillingClick} />}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="p-3 text-900 font-medium border-bottom-1 border-200">Business architecture modeling</td>
              <td className="text-center p-medium border-bottom-1 border-200"><i className="text-green-500 pi pi-check"></i></td>
              {/* <td className="text-center p-medium border-bottom-1 border-200"><i className="text-green-500 pi pi-check"></i></td> */}
              <td className="text-center p-medium border-bottom-1 border-200"><i className="text-green-500 pi pi-check"></i></td>
            </tr>
            <tr>
              <td className="p-3 text-900 font-medium border-bottom-1 border-200">IT architecture modeling</td>
              <td className="text-center p-medium border-bottom-1 border-200"><i className="text-green-500 pi pi-check"></i></td>
              {/* <td className="text-center p-medium border-bottom-1 border-200"><i className="text-green-500 pi pi-check"></i></td> */}
              <td className="text-center p-medium border-bottom-1 border-200"><i className="text-green-500 pi pi-check"></i></td>
            </tr>
            <tr>
              <td className="p-3 text-900 font-medium border-bottom-1 border-200">Designing for cloud architecture</td>
              <td className="text-center p-medium border-bottom-1 border-200"><i className="text-green-500 pi pi-check"></i></td>
              {/* <td className="text-center p-medium border-bottom-1 border-200"><i className="text-green-500 pi pi-check"></i></td> */}
              <td className="text-center p-medium border-bottom-1 border-200"><i className="text-green-500 pi pi-check"></i></td>
            </tr>
            <tr>
              <td className="p-3 text-900 font-medium border-bottom-1 border-200">Export diagram to image</td>
              <td className="text-center p-medium border-bottom-1 border-200"><i className="text-green-500 pi pi-check"></i></td>
              {/* <td className="text-center p-medium border-bottom-1 border-200"><i className="text-green-500 pi pi-check"></i></td> */}
              <td className="text-center p-medium border-bottom-1 border-200"><i className="text-green-500 pi pi-check"></i></td>
            </tr>
            {/* <tr>
              <td className="p-3 text-900 font-medium border-bottom-1 border-200">Export diagram to SVG image</td>
              <td className="text-center p-medium border-bottom-1 border-200"><i className="text-500 pi pi-times"></i></td>
              #<td className="text-center p-medium border-bottom-1 border-200"><i className="text-green-500 pi pi-check"></i></td>
              <td className="text-center p-medium border-bottom-1 border-200"><i className="text-green-500 pi pi-check"></i></td>
            </tr> */}
            <tr>
              <td className="p-3 text-900 font-medium border-bottom-1 border-200">Number of projects</td>
              <td className="text-center p-medium border-bottom-1 border-200">1</td>
              {/* <td className="text-center p-medium border-bottom-1 border-200">Unlimited</td> */}
              <td className="text-center p-medium border-bottom-1 border-200">Unlimited</td>
            </tr>
            <tr>
              <td className="p-3 text-900 font-medium border-bottom-1 border-200">Diagrams in project</td>
              <td className="text-center p-medium border-bottom-1 border-200">Up to 10</td>
              {/* <td className="text-center p-medium border-bottom-1 border-200">Unlimited</td> */}
              <td className="text-center p-medium border-bottom-1 border-200">Unlimited</td>
            </tr>
            {/* <tr>
              <td className="p-3 text-900 font-medium border-bottom-1 border-200">Project members</td>
              <td className="text-center p-medium border-bottom-1 border-200"><i className="text-500 pi pi-times"></i></td>
              #<td className="text-center p-medium border-bottom-1 border-200"><i className="text-500 pi pi-times"></i></td>
              <td className="text-center p-medium border-bottom-1 border-200"><i className="text-green-500 pi pi-check"></i></td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export { Pricing };
