import React from "react";
import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";

export type Props = {
  onClick: () => any,
}

const GetStarted = ({ onClick }: Props) => {
  return (
    <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
      <div className="flex flex-column lg:flex-row align-items-center justify-content-evenly">
        <div className="text-center lg:text-left lg:pr-8">
          <div className="text-blue-600 font-medium mb-1 text-xl">TAKE THE NEXT STEP</div>
          <div className="text-900 font-bold text-6xl mb-5">Get Started with Dopredu</div>

          <span className="inline-flex align-items-center bg-blue-50 px-3 py-2 mr-2 mb-2 lg:mb-0 border-1 border-blue-200" style={{ borderRadius: '50px' }}>
            <i className="pi pi-check text-blue-500 mr-2"></i>
            {/* <span className="text-gray-700 font-medium">1 Month Free Trial</span> */}
            <span className="text-gray-700 font-medium">Start for Free</span>
          </span>
          <span className="inline-flex align-items-center bg-blue-50 px-3 py-2 border-1 border-blue-200" style={{ borderRadius: '50px' }}>
            <i className="pi pi-check text-blue-500 mr-2"></i>
            <span className="text-gray-700 font-medium">No Credit Card Required</span>
          </span>
        </div>
        <div className="mt-5 lg:mt-0 flex flex-wrap lg:flex-nowrap">
          <Button label="Get Started" onClick={onClick} className="px-5 py-3 mr-0 lg:mr-3 p-button-rounded font-bold mb-3 lg:mb-0 w-full lg:w-auto white-space-nowrap" />
          {/* <Button label="Request a Demo" icon="pi pi-arrow-right" iconPos="right" className="px-5 py-3 p-button-outlined p-button-rounded font-bold w-full lg:w-auto white-space-nowrap" style={{ whiteSpace: 'nowrap' }} /> */}
        </div>
      </div>
    </div>
  );
}

export { GetStarted };
