import React from "react";
import Obfuscate from 'react-obfuscate';

export type Props = {
}

const Footer = () => {
  return (
    <div className="bg-gray-900 px-4 py-8 md:px-6 lg:px-8">
      <div className="grid">
        <div className="col-12 md:col-3 md:mb-0 mb-3">
          <img src="/static/img/logo_transparent.png" alt="Logo" width={100} />
        </div>
        <div className="col-12 md:col-3">
          <div className="text-white text-lg font-bold mb-4 flex flex-wrap" style={{ maxWidth: '290px' }}>Dopredu</div>
          <div className="text-white mb-3"><i className="pi pi-phone border-round p-1 mr-2"></i>+1 (617) 230-2049</div>
          <div className="text-white mb-3">
            <i className="pi pi-inbox border-round p-1 mr-2"></i>
            <Obfuscate className="text-white" obfuscateChildren={false} email="hi@dopredu.com" />
          </div>
        </div>
        <div className="col-12 md:col-3 md:col-offset-3 text-gray-200">
          <div className="text-white font-bold line-height-3 mb-3">Company</div>
          <a href="/privacy-policy" className="line-height-3 block cursor-pointer text-white mb-2">Privacy Policy</a>
          <a href="/terms-and-conditions" className="line-height-3 block cursor-pointer text-white mb-2">Terms and Conditions</a>
          <a className="line-height-3 block cursor-pointer text-white mb-2">Contact Us</a>
          <a className="line-height-3 block cursor-pointer text-white mb-2">About Us</a>
        </div>
      </div>
    </div>
  );
}

export { Footer };
