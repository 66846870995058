import React from "react";
import { Button } from "primereact/button";

export type Props = {
  onClick: () => any,
}

const Hero = ({ onClick }: Props) => {
  return (
    <div className="grid grid-nogutter surface-section text-800">
      <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
        <section>
          <p className="text-6xl font-bold">
          <span>Modeling and designing for modern </span>
          <span className="text-primary">IT architects, analysts, and developers</span>
          </p>
          <p className="mt-0 mb-4 text-700 line-height-3">Model your business and software architecture properly. Dopredu is a professional tool dedicated to designing and modeling business and IT architecture.</p>

          <Button label="Start modeling" type="button" className="mr-3 p-button-raised p-button-lg" onClick={onClick}></Button>
          {/* <Button label="Live Demo" type="button" className="p-button-outlined"></Button> */}
        </section>
      </div>
      <div className="col-12 md:col-6 overflow-hidden">
        <img src="/static/img/hero1.jpg" alt="hero-1" className="md:ml-auto block md:h-full" style={{ clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)' }} />
      </div>
    </div>
  );
}

export { Hero };
