import React from "react";
import { gql } from "@apollo/client";
import { Controller, useForm } from "react-hook-form";
import { signIn } from "next-auth/react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Password } from 'primereact/password';
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { classNames } from "primereact/utils";
import { SignUpInput, useSignUpMutation } from "generated/graphql";

export type Props = {
  show: any,
  handleClose: any,
  openLogInDialog: () => void,
}

const CREATE_DIAGRAM = gql`
  mutation SignUp($input: SignUpInput!) {
    signUp(input: $input) {
      success
      error {
        message
        details {
          field
          errors
        }
      }
    }
  }
`;

const SignUpDialog = ({ show, handleClose, openLogInDialog }: Props) => {
  const [signUpMutation, { data, loading, error }] = useSignUpMutation();

  const { control, formState: { errors }, handleSubmit, setError } = useForm({
    defaultValues: {
      name: '',
      lastName: '',
      email: '',
      password: '',
      accept: false,
    },
  });

  const onSubmit = async (data) => {
    console.log(data);
    const result = await signUpMutation({ variables: { input: data } });
    console.log(result);
    if (result.data?.signUp?.error) {
      // console.log('errors', result.data.createDiagram.error.details, result.data.createDiagram.error.details[0].field.replace('input.', ''));
      result.data.signUp.error.details!.forEach(error => setError(error.field.replace('input\.', '') as keyof SignUpInput, { type: "server", message: error.errors[0] }));
    } else {
      await signIn('credentials', { email: data.email, password: data.password, callbackUrl: '/projects' });
    }
    // handleClose();
  };

  return (<>
    <Dialog header="Sign up" visible={show} onHide={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">

        <div className="grid p-fluid">
          <div className="col-12 md:col-6">
            <div className="field">
              <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>First name</label>
              <Controller name="name" control={control} render={({ field, fieldState }) => (<>
                <InputText id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                {fieldState.error && <small id="username2-help" className="p-error block">{fieldState.error.message}</small>}
              </>)} />
            </div>
          </div>

          <div className="col-12 md:col-6">
            <div className="field">
              <label htmlFor="lastName" className={classNames({ 'p-error': errors.lastName })}>Last name</label>
              <Controller name="lastName" control={control} render={({ field, fieldState }) => (<>
                <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                {fieldState.error && <small id="username2-help" className="p-error block">{fieldState.error.message}</small>}
              </>)} />
            </div>
          </div>
        </div>


        <div className="field">
          <label htmlFor="email" className={classNames({ 'p-error': errors.email })}>E-mail</label>
          <Controller name="email" control={control} render={({ field, fieldState }) => (<>
            <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
            {fieldState.error && <small id="username2-help" className="p-error block">{fieldState.error.message}</small>}
          </>)} />
        </div>
        <div className="field">
          <label htmlFor="password" className={classNames({ 'p-error': errors.password })}>Password</label>
          <Controller name="password" control={control} render={({ field, fieldState }) => (<>
            <Password id={field.name} {...field} toggleMask feedback={false} className={classNames({ 'p-invalid': fieldState.invalid })} />
            {fieldState.error && <small id="username2-help" className="p-error block">{fieldState.error.message}</small>}
          </>)} />
        </div>
        <div className="field-checkbox">
          <Controller name="accept" control={control} render={({ field, fieldState }) => (
            <Checkbox inputId={field.name} onChange={(e) => field.onChange(e.checked)} checked={field.value} className={classNames({ 'p-invalid': fieldState.invalid })} />
          )} />
          <label htmlFor="accept" className={classNames({ 'p-error': errors.accept })}>I agree to the terms and conditions</label>

          {/* <Controller name="agree" control={control} render={({ field, fieldState }) => (<>
            <div className="flex align-items-center">
              <Checkbox id={field.agree} {...field} className={classNames('mr-2', { 'p-invalid': fieldState.invalid })} />
              <label htmlFor={field.agree} className={classNames({ 'p-error': fieldState.invalid })}>I agree to Terms and conditions</label>
            </div>
            {fieldState.error && <small id="username2-help" className="p-error block">{fieldState.error.message}</small>}
          </>)} /> */}
        </div>
        <Button label="Sign up" loading={loading} icon="pi pi-user" className="w-full" type="submit" />
      </form>
      <div className="mt-6 text-center text-600">
        Have an account already? <a className="font-medium text-blue-500 cursor-pointer" onClick={openLogInDialog}>Log in</a>
      </div>
    </Dialog>
  </>);
}

export default SignUpDialog;
