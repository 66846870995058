import { gql, useQuery } from '@apollo/client';
import { initializeApollo } from 'apollo/client';
import { Footer } from 'components/content/Footer';
import { Hero } from 'components/content/Hero';
import { GetStarted } from 'components/content/home/GetStarted';
import { Pricing } from 'components/content/home/Pricing';
import Layout from 'components/content/Layout';
import LogInDialog from 'components/user/LogInDialog';
import SignUpDialog from 'components/user/SignUpDialog';
import useLoginSignupDialogs from 'components/user/useLoginSignupDialogs';
import { IndexPageQuery } from 'generated/graphql';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import { useState } from 'react';
import Homepage from '../components/content/Homepage';

export const INDEX_PAGE_QUERY = gql`
    query IndexPage {
        viewer {
            name
            email
            ...Layout_viewer
        }
    }
    ${Layout.fragments.viewer}
`;


function IndexPage() {
  const router = useRouter();
  const openProjects = () => router.push('/projects');
  const openBilling = () => router.push('/profile');
  const { showLogInDialog, showSignUpDialog, openSignUpDialog, openLogInDialog, closeLoginSignupDialogs } = useLoginSignupDialogs();
  const { loading, error, data } = useQuery<IndexPageQuery>(INDEX_PAGE_QUERY);
  const openSignUpOrDashboard = data?.viewer ? openProjects : openSignUpDialog;
  return <Layout viewer={data?.viewer!} openLogInDialog={openLogInDialog} openSignUpDialog={openSignUpDialog} marginless={true}>
    <Hero onClick={openSignUpOrDashboard} />
    <Homepage onClick={openSignUpOrDashboard} />
    <Pricing onSignUpClick={openSignUpOrDashboard} onOpenBillingClick={openBilling} loggedIn={!!data?.viewer} />
    <GetStarted onClick={openSignUpOrDashboard} />
    <Footer />
    <LogInDialog show={showLogInDialog} handleClose={closeLoginSignupDialogs} openSignUpDialog={openSignUpDialog} />
    <SignUpDialog show={showSignUpDialog} handleClose={closeLoginSignupDialogs} openLogInDialog={openLogInDialog} />
  </Layout>
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const apolloClient = initializeApollo(null, context);
  const { data } = await apolloClient.query({ query: INDEX_PAGE_QUERY });
  return {
    props: {
      initialApolloState: apolloClient.cache.extract(),
    },
  }
}

export default IndexPage;
