import { useState } from "react";
import { gtagEvent } from "service/analytics";

const useLoginSignupDialogs = () => {
  const [showSignUpDialog, setShowSignUpDialog] = useState(false);
  const [showLogInDialog, setShowLogInDialog] = useState(false);
  const openSignUpDialog = () => {
    setShowLogInDialog(false);
    setShowSignUpDialog(true);
    gtagEvent('signup_open');
  };
  const openLogInDialog = () => {
    setShowSignUpDialog(false);
    setShowLogInDialog(true);
    gtagEvent('login_open');
  };
  const closeLoginSignupDialogs = () => {
    showLogInDialog && gtagEvent('login_close');
    showSignUpDialog && gtagEvent('signup_close');
    setShowSignUpDialog(false);
    setShowLogInDialog(false);
  };
  return {
    showSignUpDialog,
    showLogInDialog,
    openSignUpDialog,
    openLogInDialog,
    closeLoginSignupDialogs,
  };
};

export default useLoginSignupDialogs;
