import React from "react";
import { Controller, useForm } from "react-hook-form";
import { signIn } from "next-auth/react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Password } from 'primereact/password';
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

export type Props = {
  show: any,
  handleClose: any,
  openSignUpDialog: () => void,
}


const LogInDialog = ({ show, handleClose, openSignUpDialog }: Props) => {
  const { control, formState: { errors }, handleSubmit, setError } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = async (data) => {
    // console.log(data);
    const status: any = await signIn('credentials', { email: data.email, password: data.password, redirect: false });
    // console.log(status);
    if (status?.ok) {
      window.location.href = '/projects';
    } else {
      setError("email", { type: "server" });
      setError("password", { type: "server", message: "Incorrect e-mail or password" });
    }
  };

  return (<>
    <Dialog header="Log in" visible={show} onHide={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
        <div className="field">
          <label htmlFor="email" className={classNames({ 'p-error': errors.email })}>E-mail</label>
          <Controller name="email" control={control} rules={{ required: 'E-mail is required' }} render={({ field, fieldState }) => (<>
            <InputText id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
            {fieldState.error && <small id="username2-help" className="p-error block">{fieldState.error.message}</small>}
          </>)} />
        </div>
        <div className="field">
          <label htmlFor="password" className={classNames({ 'p-error': errors.password })}>Password</label>
          <Controller name="password" control={control} rules={{ required: 'Password is required' }} render={({ field, fieldState }) => (<>
            <Password id={field.name} {...field} toggleMask feedback={false} className={classNames({ 'p-invalid': fieldState.invalid })} />
            {fieldState.error && <small id="username2-help" className="p-error block">{fieldState.error.message}</small>}
          </>)} />
        </div>
        <Button label="Log in" icon="pi pi-user" className="w-full" type="submit" />
      </form>
      <div className="mt-6 text-center text-600">
          Don't have an account? <a className="font-medium text-blue-500 cursor-pointer" onClick={openSignUpDialog}>Sign up</a>
      </div>
    </Dialog>
  </>);
}

export default LogInDialog;
